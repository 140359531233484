<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>{{collectionsConfig.getCollectionLabel(collectionId)}}</v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <v-btn
          key="save"
          text
          @click="saveChanges"
          :loading="loading || saving"
          :disabled="changeSet === null"
          >
          <v-icon>save</v-icon>&nbsp;{{translate('Save', 'save')}}
        </v-btn>
      </v-toolbar-items>
    </template>

    <v-card>
      <v-card-title>
        {{collectionsConfig.getCollectionLabel(collectionId)}}
      </v-card-title>
      <v-card-text>
        <Tree
          class="hierarchy-tree"
          :value="treeNodes"
          :unfoldWhenDragover="false"
          @change="handleChange">
          <span slot-scope="{node, index, path, tree}">
            <v-row>
              <v-col class="cell">
                <v-btn
                  text
                  small @click="tree.toggleFold(node, path)"
                  v-if="node.children.length"
                  >
                  <v-icon small>{{node.$folded ? 'fa-plus' : 'fa-minus'}}</v-icon>
                </v-btn>
              </v-col>
              <v-col class="cell" v-for="h in attributeHeaders" :key="h.value" :class="'text-left'">
                <component
                  :is="h.component"
                  :header="h"
                  :item="node"
                  :schema="schema"
                  />
              </v-col>
              <v-col class="cell">
                <v-btn
                  small icon color="primary"
                  :to="{name: 'collection-item', params: {itemKey: node.item.key, collectionId}}">
                  <v-icon>edit</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </span>
        </Tree>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import 'he-tree-vue/dist/he-tree-vue.css'
import {Tree, Draggable, Fold} from 'he-tree-vue'

import sortBy from 'lodash/sortBy'

const toMap = (list, keyFn, valueFn = (item => item)) => list.reduce((memo, item) => {
  memo[keyFn(item)] = valueFn(item)
  return memo
}, {})

// Extend existing/new with props to keyed object
const updateMap = (map, key, data) => {
  let m = map || {}
  m[key] = {...m[key], ...data}
  return m
}

export default {
  props: ['collectionId'],
  data: () => ({
    stateCache: null,
    changeSet: null,
    savingChanges: false
  }),
  components: {
    LayoutPage,
    Tree: Tree.mixPlugins([Draggable, Fold])
  },
  created () {
    this.stateCache = this.getStateCache(`hierarchy.${this.collectionId}`)
  },
  computed: {
    ...mapGetters(['api', 'collectionsConfig', 'getStateCache', 'getAttributeCellComponent', 'currentMarketId', 'translate']),
    schema () { return this.collection.schema },
    loading () { return this.collection.loading },
    saving () { return this.savingChanges },
    treeNodes () {
      return this.calculateNodes()
    },
    attributeHeaders () {
      const getAttributeValuePath = ({name, multimarket}) => multimarket ? `item.item.${name}.${this.currentMarketId}` : `item.item.${name}`
      return this.collectionsConfig.mapCollectionTableAttributes(this.schema)
        .map(({attribute, label}) => ({
          text: label,
          value: getAttributeValuePath(attribute),
          sortable: this.sortable,
          component: this.getAttributeCellComponent(attribute)
        }))
    },

  },
  watch: {
    treeNodes: {
      handler (nodes) {
        if (nodes && nodes.length && this.stateCache) {
          let expanded = rec(nodes, [])
          // console.log({expanded})
          this.stateCache.set('expanded', expanded)
        }
        function rec(nodes, l) {
          nodes.forEach(node => !node.$folded && l.push(node.key))
          nodes.forEach(node => rec(node.children))
          return l
        }
      },
      deep: true,
      initial: true
    }
  },
  asyncComputed: {
    collection: {
      async get () {
        let loadAttributes = this.collectionsConfig.getTableAttributesForLoading(this.collectionId)
        let cd = await this.api.collections.getCollection({collectionId: this.collectionId, query: {
          attributes: loadAttributes && loadAttributes.join(',')
        }})
        return cd
      },
      default: {
        loading: true,
        items: [],
        schema: {},
        channels: {},
        relations: {},
        reverseRelations: {}
      }
    }
  },
  methods: {
    calculateNodes () {
      if (!(this.collection && this.collection.schema)) {
        return []
      }
      let expandedKeys = this.stateCache ? toMap(Object.values(this.stateCache.get('expanded') || []), key => key) : {}

      const isNode = n => n && n.key

      let {key, parentKey} = this.collection.schema
      if (!(key && parentKey)) {
        return []
      }
      let items = sortBy(this.collection.items, item => item.childIndex || 0)

      let nodes = items.map((item) => ({
        key: item.key,
        pkey: item.parentKey,
        index: item.childIndex || '',
        item,
        children: [],
        $folded: expandedKeys[item.key] !== item.key
      }))
      let nodesByKey = toMap(nodes, ({key}) => key)

      // Build tree by assigning children
      nodes.map(n => ({
        n,
        pn: nodesByKey[n.pkey]
      }))
      .filter(({pn}) => isNode(pn))
      .forEach(({n, pn}) => pn.children.push(n))

      let roots = nodes.filter(({pkey}) => !isNode(nodesByKey[pkey]))

      this.normalizeNodes(roots)

      return roots
    },
    // recurse nodes and apply initial values for sort index
    normalizeNodes (nodes, parentNode) {
      this.normalizeNodesRecursively(nodes, parentNode)
      // hack to fixup that a node is moved to root
      // For this, we must ensure that parent key is cleared
      const nodesByKey = toMap(nodes, ({key}) => key)

      nodes.filter(({pkey}) => nodesByKey.hasOwnProperty(pkey)).forEach(node => node.pkey = null)
    },
    normalizeNodesRecursively (nodes, parentNode) {
      nodes.forEach((node, index) => {
        node.pkey = parentNode ? parentNode.key : null
        node.index = index
        this.normalizeNodesRecursively(node.children, node)
      })
    },

    // detect changes to parent and ordering and apply to map suitable for update
    getNodeChanges (nodes, parentNode, changesByKey = null) {
      let {key, parentKey, childIndexKey} = this.collection.schema
      let parentNodeKey = parentNode ? parentNode.key : null
      if (parentKey) {
        changesByKey = nodes
          .filter(node => node.pkey !== parentNodeKey)
          .reduce((cbk, node) => updateMap(cbk, node.key, {[key]: node.key, [parentKey]: parentNodeKey}), changesByKey)
      }
      if (childIndexKey) {
        // we do have a double check here
        // to fore a full update of siblings (with default sort order of 0)
        // whenever order changes
        if (nodes.some((node, index) => node.index !== index)) {
          changesByKey = nodes
            .reduce((cbk, node, index) =>
              (node.index === index) && (node[childIndexKey] === index) ? cbk : updateMap(cbk, node.key, {[key]: node.key, [childIndexKey]: index}), changesByKey)
        }
      }
      return nodes.reduce((cbk, node) =>  this.getNodeChanges(node.children, node, cbk), changesByKey)
    },

    // react to changes in tree component and update changeset accordingly
    handleChange () {
      let changes = this.getNodeChanges(this.treeNodes)
      this.changeSet = changes
    },

    async saveChanges () {
      this.savingChanges = true
      try {
        await this.api.collections.updateCollection({
          update: {
            collectionId: this.collectionId,
            overwrite: true,
            items: Object.values(this.changeSet)
          }
        })
        this.changeSet = null
        this.normalizeNodes (this.treeNodes)
      }
      finally {
        this.savingChanges = false
      }
    }
  }
}
</script>
<style>
  .hierarchy-tree .cell {
    padding: 0;
  }
  .hierarchy-tree .tree-node {
    border-style: none;
    border-bottom: 1px solid rgba(0,0,0,.12);
  }
</style>
